import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Hidden, AppBar, Tab, Tabs, Badge } from '@mui/material';
import MESSAGES from '../../helpers/messages';

import { TabContainer, TabMenu } from './';
import withMediaQuery from '../withMediaQuery';

class TabBar extends Component {
	state = {
		value: this.props.tabValue ? this.props.tabValue : 0,
	};

	componentDidMount() {
		this.handleNewMessagesSetReadby(this.state.value);
	}

	componentDidUpdate(prevProps) {
		if (
			((!prevProps.channel && this.props.channel) ||
				(this.props.channel && prevProps.tabActive !== this.props.tabActive && this.props.tabActive == 1)) &&
			this.props.unread.length > 0
		) {
			this.handleNewMessagesSetReadby(this.props.tabActive);
		}
		if (this.props.tabActive !== this.state.value) {
			this.menuTabActive(this.props.tabActive);
		}

		if (this.props.tabActive == 1 && !prevProps.newMessages && this.props.newMessages) {
			this.handleNewMessagesSetReadby(this.props.tabActive);
		}
	}

	handleNewMessagesSetReadby = (tabActive) => {
		if (tabActive == 1 && typeof this.props.newMessages !== 'undefined' && this.props.newMessages) {
			this.handleSetMessagesRead([
				MESSAGES.REF.TICKETS_TICKETS,
				MESSAGES.REF.TICKETS_FOLLOWUP,
				MESSAGES.REF.TICKETS_APPOINTMENTS,
				MESSAGES.REF.TICKETS_MEETINGS,
				MESSAGES.REF.TICKETS_FILES,
				MESSAGES.REF.TICKETS_QUESTIONNAIRES,
				MESSAGES.REF.TICKETS_ACCESS,
				MESSAGES.REF.CHAT_MESSAGES,
				MESSAGES.REF.TICKETS_PRESCRIPTIONS,
			]);
		}
		if (tabActive == 2 && typeof this.props.newMessages !== 'undefined' && this.props.newMessages) {
			this.handleSetMessagesRead([MESSAGES.REF.TICKETS_APPOINTMENTS]);
		}
	};

	handleSetMessagesRead = (ref_types) => {
		let aux = [];
		if (typeof ref_types !== 'undefined' && ref_types.length > 0) {
			ref_types.forEach((ref_type) => {
				if (this.props.unread.some((msg) => msg.ref_type == ref_type)) {
					this.props.sendNotificationAction(
						{
							type: 'readmark',
							link_type: 'readmark',
							origin_type: MESSAGES.ORIG.TICKETS,
							origin_id: this.props.ticket_id,
							ref_type: ref_type,
							ref_id: '0',
							text: 'event_ticket_read',
						},
						() => aux.push(ref_type)
					);
				} else {
					return true;
				}
			});
		}
	};

	menuTabActive = (tabActive) => {
		this.handleChange(tabActive);
		this.handleChangeIndex(tabActive);
		this.a11yProps(tabActive);
	};

	handleChange = (event, newValue) => {
		this.setState({ value: newValue });
		this.props.changeActiveTab(newValue);
	};

	handleChangeIndex = (index) => {
		this.setState({ value: index });
		this.props.changeActiveTab(index);
	};

	a11yProps = (index) => {
		return {
			id: `tab-${index}`,
			'aria-controls': `tabpanel-${index}`,
		};
	};

	render() {
		const { theme } = this.props;

		return (
			<div>
				{['md', 'lg', 'xl'].includes(this.props.width) ? (
					<Tabs
						value={this.state.value}
						onChange={this.handleChange}
						centered
						sx={{ borderBottom: 2, borderColor: 'divider' }}
						indicatorColor="secondary"
						textColor="primary"
						variant="fullWidth"
						scrollButtons="auto"
						aria-label="Ticket Overview Tabs"
            role="tablist"
					>
						{this.props.tabConfig.tab.tabs.map((tab, i) => {
							return (
								!tab.hidden && (
									<Tab
										key={i}
										ref={(ref) => this.props.setTabRef(ref, i)}
										id={`tab-${tab.label}`}
										aria-controls={`tabpanel-${tab.label}`}
										aria-selected={this.state.value === i}
										role="tab"
                    tabIndex={0}
										label={
											tab.badge ? (
												<Badge
													variant={tab.badge.variant ? tab.badge.variant : 'standard'}
													badgeContent={tab.badge.content}
													invisible={tab.badge.invisible}
													color={tab.badge.color}
													classes={{ badge: tab.badge.customClass }}
												>
													{tab.label}
												</Badge>
											) : (
												tab.label
											)
										}
										{...this.a11yProps(i)}
									/>
								)
							);
						})}
						{this.props.tabConfig.menu && this.props.tabConfig.menu.actions.length > 0 && this.props.tabConfig.menu.active && (
							<TabMenu options={this.props.tabConfig.menu.actions} menuAction={(index) => this.props.menuAction(index)} />
						)}
					</Tabs>
				) : (
					<Tabs
						value={this.state.value}
						onChange={this.handleChange}
						aria-label="full width tabs example"
						sx={{ borderBottom: 2, borderColor: 'divider' }}
						indicatorColor="secondary"
						textColor="primary"
						variant="fullWidth"
						scrollButtons="auto"
					>
						{this.props.tabConfig.tab.tabs.map((tab, i) => {
							return (
								!tab.hidden && (
									<Tab
										key={i}
										icon={
											tab.badge ? (
												<Badge
													variant={tab.badge.variant ? tab.badge.variant : 'standard'}
													badgeContent={tab.badge.content}
													invisible={tab.badge.invisible}
													color={tab.badge.color}
													classes={{ badge: tab.badge.customClass }}
												>
													{tab.icon}
												</Badge>
											) : (
												tab.icon
											)
										}
										ref={(ref) => this.props.setTabRef(ref, i)}
										{...this.a11yProps(i)}
									/>
								)
							);
						})}
						{this.props.tabConfig.menu && this.props.tabConfig.menu.active && (
							<TabMenu options={this.props.tabConfig.menu.actions} menuAction={(index) => this.props.menuAction(index)} />
						)}
					</Tabs>
				)}
				{this.props.tabConfig.tab.tabs.map((tab, i) => {
					return (
						!tab.disabled && (
							<TabContainer key={i} value={this.state.value} index={i} dir={theme.direction}>
								{typeof tab.component === 'function' ? tab.component({ channel: this.props.channel }) : tab.component}
							</TabContainer>
						)
					);
				})}
			</div>
		);
	}
}

export default withStyles(null, { withTheme: true })(withMediaQuery()(TabBar));
